<template>
  <div
    class="w-100 d-flex align-items-center justify-content-center border-light rounded shadow-sm my-50"
  >
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
      class="w-100"
    >
      <div class="w-100 d-flex align-items-center justify-content-between m-1">
        <div
          class="d-flex flex-column align-items-start justify-content-start w-auto"
        >
          <p class="mb-0 font-weight-bold">{{ title }}</p>
          <div
            class="d-flex flex-row align-items-center justify-content-start mt-50"
          >
            <small class="mr-50 font-weight-bold">Role:</small>
            <b-form-select
              v-model="role"
              :options="roleOptions"
              size="sm"
              @change="handleRoleChange"
            />
          </div>
        </div>

        <b-form-checkbox
          v-b-tooltip.hover.top.v-warning="{ disabled: role != null }"
          title="Select a role to enable this Workspace."
          @change="handleSelectionValueChange"
          v-model="isSelected"
          name="check-button"
          switch
          inline
          :disabled="role == null"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormCheckbox,
  BFormGroup,
  BBadge,
  VBTooltip,
  BTooltip,
  BOverlay,
} from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from "../../../mixins/UserMixins";

export default {
  mixins: [ResponseMixins, UserMixins],
  components: {
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    BBadge,
    BTooltip,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      isSelected: false,
      role: null,
      roles: [],
      showOverlay: false,
    };
  },

  computed: {
    roleOptions() {
      const newRoles = [{ text: "Select", value: null }];
      this.roles.map((role) => {
        newRoles.push({ text: role.name, value: role.name });
      });
      return newRoles;
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    moduleId: {
      type: String,
      required: true,
    },

    preSelectedRole:{
      type: String,
      default: null,
    },

    userId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      if(this.preSelectedRole && this.preSelectedRole!==null){
        this.isSelected = true;
        this.role = this.preSelectedRole;
      }
      this.getRolesFromTenantAndSetData();
    },

    handleRoleChange(value) {
      if (value != null) {
        this.isSelected = true;
        this.$emit("input", { id: this.moduleId, role: value, value: true });
      } else {
        this.isSelected = false;
        this.$emit("input", { id: this.moduleId, role: value, value: false });
      }
    },

    getRolesFromTenantAndSetData() {
      this.showOverlay = true;
      const params = { module_id: this.moduleId, user_id: this.userId };
      this.getRolesFromTenant(params)
        .then((res) => {
          console.log("Module Roles", res);
          this.roles = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSelectionValueChange(value) {
      if (value != true) {
        this.role = null;
      }

      this.$emit("input", { id: this.moduleId, role: this.role, value: value });
    },
  },
};
</script>

<style>
</style>