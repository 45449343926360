<template>
  <div
    class="d-flex flex-row align-items-start justify-content-start h-auto border-light shadow-sm p-1 position-relative"
    style="min-height: 80px; width: 48%"
  >
    <div class="d-flex align-items-start justify-content-center mr-50 h-100">
      <feather-icon :icon="icon" size="16" />
    </div>

    <div
      class="d-flex flex-column align-items-start justify-content-start h-100 w-100"
    >
      <p class="mb-0">{{ title }}</p>

      <h5
        class="mb-0 mt-25 w-100 pr-1"
        style="word-wrap: break-word; display: inline-block"
      >
        {{ value }}
      </h5>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: { FeatherIcon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>