<template>
  <sidebar-template title="User Details" :closeAction="closeAction">
    <template #content>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="w-100 h-auto d-flex flex-column align-items-center justify-content-start"
        >
          <div class="w-100 d-flex align-items-start justify-content-start">
            <div class="d-flex align-items-start justify-content-center mr-1">
              <div
                class="bg-light-secondary rounded-lg d-flex flex-column align-items-center justify-content-center"
                style="width: 80px; height: 80px"
              >
                <feather-icon icon="UserIcon" size="48" />
              </div>
            </div>

            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h3>{{ user.firstname }} {{ user.lastname }}</h3>
              <p>
                <b-badge variant="primary">{{
                  mapUserType(user.user_type)
                }}</b-badge>
              </p>
            </div>
          </div>

          <app-collapse class="custom-collapse w-100 shadow-none">
            <app-collapse-item
              :title="editMode ? 'Edit Deatils' : 'Basic Info'"
              class="shadow-none"
              :isVisible="isVisible"
            >
              <template v-slot:header-buttons>
                <b-button
                  v-if="!editMode"
                  @click.stop="handleEditClick"
                  variant="outline-info"
                  size="sm"
                  class="ml-50"
                  ><feather-icon icon="EditIcon" class="mr-25" />
                  <span class="align-middle">Edit</span>
                </b-button>
                <!-- <b-button
                  v-if="editMode"
                  @click.stop="handleEditCancelClick"
                  variant="flat-danger"
                  size="sm"
                  ><span class="align-middle"
                    ><feather-icon icon="RefreshCcwIcon" class="mr-25" /><span
                      class="align-middle"
                      >Cancel</span
                    ></span
                  ></b-button
                > -->
              </template>

              <div
                v-if="!editMode"
                class="d-flex flex-column align-items-center justify-content-start w-100"
              >
                <div
                  class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
                >
                  <user-detail-header-item
                    icon="UserIcon"
                    title="First Name"
                    :value="user.firstname"
                  />

                  <user-detail-header-item
                    icon="UserIcon"
                    title="Last Name"
                    :value="user.lastname"
                  />
                </div>
                <div
                  class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
                >
                  <user-detail-header-item
                    icon="MailIcon"
                    title="Email"
                    :value="user.email"
                  />
                  <user-detail-header-item
                    icon="PhoneIcon"
                    title="Mobile"
                    :value="user.mobile"
                  />
                </div>
                <!-- <div
                  class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
                >
                  <user-detail-header-item
                    icon="GridIcon"
                    title="Type"
                    :value="user.user_type"
                  />
                  <user-detail-header-item
                    icon="PlusCircleIcon"
                    title="Added From"
                    :value="user.added_from"
                  />
                </div> -->
              </div>
              <user-details-edit-form
                v-if="editMode && Object.keys(user).length > 0"
                :userId="userId"
                :userData="user"
                :handleCancelClick="handleEditCancelClick"
                :reloadParent="handleParentReload"
              />
            </app-collapse-item>
          </app-collapse>

          <!-- <div
            class="w-100 d-flex flex-column align-items-start justify-content-start mt-2"
          >
            <h5 class="mb-0 font-weight-bold">Basic Info</h5>
            <hr class="w-100 my-25" />

            <div
              class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
            >
              <user-detail-header-item
                icon="UserIcon"
                title="First Name"
                :value="user.firstname"
              />

              <user-detail-header-item
                icon="UserIcon"
                title="Last Name"
                :value="user.lastname"
              />
            </div>
            <div
              class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
            >
              <user-detail-header-item
                icon="MailIcon"
                title="Email"
                :value="user.email"
              />
              <user-detail-header-item
                icon="PhoneIcon"
                title="Phone"
                :value="user.mobile"
              />
            </div>

            <div
              class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
            >
              <user-detail-header-item
                icon="GridIcon"
                title="Type"
                :value="user.user_type"
              />
              <user-detail-header-item
                icon="PlusCircleIcon"
                title="Added From"
                :value="user.added_from"
              />
            </div>
          </div> -->

          <div
            class="w-100 d-flex flex-column align-items-start justify-content-start mt-1"
          >
            <h5 class="mb-0 font-weight-bold">Modules</h5>
            <hr class="w-100 my-25" />

            <div
              class="w-100 d-flex flex-row align-items-start justify-content-between flex-wrap my-75"
            >
              <!-- <template v-for="workspace in user.workspaces">
                <workspace-detail-card
                  :key="workspace.tenant_uid"
                  :title="workspace.name"
                  :modules="workspace.enabled_modules"
                />
              </template> -->

              <template v-for="module in allModules">
                <workspace-selection-card
                  :key="module.module._id"
                  :title="module.module.name"
                  :moduleId="module.module._id"
                  :userId="userId"
                  :preSelectedRole="module.role"
                  @input="handleModuleChange"
                />
              </template>
            </div>
          </div>
        </div>
      </b-overlay>
    </template>
  </sidebar-template>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from "../../../mixins/UserMixins";
import UserDetailHeaderItem from "./UserDetailHeaderItem.vue";
import { BOverlay, BBadge, BButton } from "bootstrap-vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import WorkspaceDetailCard from "./WorkspaceDetailCard.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import WorkspaceSelectionCard from "./WorkspaceSelectionCard.vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import UserDetailsEditForm from "./UserDetailsEditForm.vue";
export default {
  mixins: [UserMixins, ResponseMixins],
  components: {
    UserDetailHeaderItem,
    BOverlay,
    SidebarTemplate,
    BBadge,
    WorkspaceDetailCard,
    AppCollapse,
    AppCollapseItem,
    WorkspaceSelectionCard,
    BButton,
    FeatherIcon,
    UserDetailsEditForm,
  },

  data() {
    return {
      showOverlay: false,
      user: {},
      allModules: [],
      editMode: false,
      isVisible: false,
    };
  },

  mounted() {
    this.load();
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    defaultEditModeStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  methods: {
    load() {
      this.getUserDetailsAndSetData(this.userId);
      this.getUserModulesAndSetData(this.userId);
      if (this.defaultEditModeStatus === true) {
        this.handleEditClick();
      }
    },

    handleParentReload() {
      this.load();
      this.reloadParent();
    },

    setEditMode(value) {
      this.editMode = value;
    },

    handleEditClick() {
      this.setEditMode(true);
      this.isVisible = true;
    },

    handleEditCancelClick() {
      this.setEditMode(false);
    },

    handleModuleChange(module) {
      console.log("Module Changed", module);
      const data = { module_id: module.id };
      if (module.value === false) {
        data["remove_access"] = 1;
      } else if (module.value === true) {
        data["role"] = module.role;
      }

      this.updateUserModulesAndSetData(this.userId, data);
    },

    updateUserModulesAndSetData(userId, data) {
      this.showOverlay = true;
      this.updateUserModules(userId, data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          if (userId === this.$store.state.app.user.user_id) {
            this.$store.dispatch("app/ReloadBasedonCookie").then((res) => {
              window.location.reload();
            });
          } else {
            this.load();
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getUserModulesAndSetData(userId) {
      this.showOverlay = true;
      this.getUserModules(userId)
        .then((res) => {
          this.allModules = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getUserDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getUserDetails(id)
        .then((res) => {
          console.log("User Details", res);
          this.user = res.data.data;
        })

        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
.custom-collapse {
  .card {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>