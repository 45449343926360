<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <validation-observer ref="assess_form" #default="{ invalid }">
      <b-form @submit.prevent="handleUpdateClick">
        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="First Name">
              <validation-provider
                vid="firstname"
                #default="{ errors }"
                name="First Name"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input v-model="formData.firstname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group label="Last Name">
              <validation-provider
                vid="lastname"
                #default="{ errors }"
                name="Last Name"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input v-model="formData.lastname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <b-form-group label="Email">
          <validation-provider
            vid="email"
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input :disabled="true" v-model="formData.email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Mobile">
          <validation-provider
            vid="mobile"
            #default="{ errors }"
            name="Mobile"
            rules="phone"
          >
            <b-form-input v-model="formData.mobile" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="w-100 d-flex justify-content-end align-items-end">
          <b-button
            size="sm"
            variant="danger"
            class="mr-50"
            @click="handleCancelClick"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Cancel</span></b-button
          >
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="invalid"
          >
            <b-spinner
              v-if="isLoading"
              label="Loading..."
              small
              class="mr-50"
            />
            <feather-icon v-else icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Update</span></b-button
          >
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from "../../../mixins/UserMixins";
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import WorkspaceDetailCard from "./WorkspaceDetailCard.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import WorkspaceSelectionCard from "./WorkspaceSelectionCard.vue";
export default {
  mixins: [UserMixins, ResponseMixins],
  components: {
    BOverlay,
    SidebarTemplate,
    BBadge,
    WorkspaceDetailCard,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    WorkspaceSelectionCard,
    BFormCheckbox,
  },

  data() {
    return {
      showOverlay: false,
      isLoading: false,
      user: {},
      currentTenant: null,
      allModules: [],

      formData: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
      },
    };
  },

  mounted() {
    this.load();
  },

  watch: {
    formData: {
      deep: true,
      handler: function (newValue) {
        console.log("Form Data", newValue);
      },
    },
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    handleCancelClick: {
      type: Function,
      required: true,
    },
  },

  methods: {
    load() {
      const newFormData = {
        firstname: this.userData.firstname,
        lastname: this.userData.lastname,
        email: this.userData.email,
        mobile: this.userData.mobile,
      };
      this.formData = newFormData;
    },

    updateUserBasicDetailsAndSetData() {
      this.showOverlay = true;
      this.updateUserBasicDetails(this.userId, this.formData)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.handleCancelClick();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleUpdateClick() {
      this.updateUserBasicDetailsAndSetData();
    },
  },
};
</script>

<style>
</style>