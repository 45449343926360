<template>
  <div class="w-100 h-auto">
    <b-modal ref="copy-link-modal" hide-footer title="Invitation Link">
      <b-input-group>
        <b-form-input v-model="inviteUrl" ref="invitation_url" />
        <b-input-group-append>
          <b-button variant="outline-primary" @click="handleCopyClick">
            <feather-icon icon="CopyIcon" class="mr-50" />Copy
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-modal>
    <b-sidebar
      id="sidebar-user-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <user-details
        :closeAction="closeUserDetailsSidebar"
        :userId="selectedUserId"
        :defaultEditModeStatus="userDetailsSidebarEditMode"
        :reloadParent="load"
      />
    </b-sidebar>

    <b-sidebar
      id="sidebar-user-create"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="500px"
    >
      <user-create-sidebar
        :closeAction="closeCreateUserSidebar"
        :userId="selectedUserId"
        :reloadParent="load"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h3 class="mb-25 font-weight-bolder">User Management</h3>
            <p class="mb-0">
              Manage all your users, Click on any row to view user details.
            </p>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <b-button
              size="sm"
              variant="success"
              @click="handleCreateUserClick($store.state.app.user.user_id)"
              ><feather-icon icon="PlusIcon" class="mr-50" />Create New
              User</b-button
            >
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="position-relative table-responsive"
          style="min-height: 60vh"
        >
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center">
                  SL No:
                </th>
                <th role="columnheader" scope="col">Full Name</th>
                <th role="columnheader" scope="col">Email</th>
                <th role="columnheader" scope="col">Mobile</th>
                <!-- <th role="columnheader" scope="col">Type</th> -->
                <th role="columnheader" scope="col" class="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="users && Object.keys(users).length > 0">
                <tr
                  role="row"
                  v-for="(user, key, index) in users"
                  :key="key"
                  @click="handleUserDetailsViewClick(key)"
                >
                  <th scope="row" class="text-center mx-1" style="width: 120px">
                    {{ index + from }}
                  </th>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                    >
                      <!-- <b-badge
                      variant="success"
                      class="text-truncate align-middle"
                      v-if="user.user_type === 'GRC_user'"
                    >
                      <feather-icon icon="StarIcon" class="mr-25" />
                      <span class="align-middle">Consultant</span>
                    </b-badge> -->

                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="`${user.firstname} ${user.lastname}`"
                      >
                        {{ `${user.firstname} ${user.lastname}` }}
                      </p>
                      <small
                        class="text-success font-weight-bold"
                        v-if="user.user_type === 'GRC_user'"
                      >
                        <b-badge variant="light-success">
                          Consultant
                        </b-badge></small
                      >
                    </div>
                  </td>
                  <td role="cell">
                    <div
                      class="d-flex align-items-center justify-content-start"
                      v-b-tooltip.hover.bottom.v-primary
                      :title="user.email"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                      >
                        {{ user.email }}
                      </p>
                    </div>
                  </td>
                  <td role="cell">
                    <div
                      class="d-flex align-items-center justify-content-start"
                      v-b-tooltip.hover.bottom.v-primary
                      :title="user.mobile"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                      >
                        {{
                          user.mobile && user.mobile != "null"
                            ? user.mobile
                            : ""
                        }}
                      </p>
                    </div>
                  </td>
                  <!-- <td role="cell">
                  <div class="d-flex align-items-center justify-content-start">
                    <b-badge variant="light-primary" class="text-truncate">
                      {{ user.user_type }}
                    </b-badge>
                  </div>
                </td> -->
                  <td
                    role="cell"
                    class="align-center flex-display text-align-right"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        size="sm"
                        text="Select"
                        variant="outline-primary"
                      >
                        <b-dropdown-group
                          id="reinvite-group"
                          header="Reinvite"
                          v-if="user.user_type !== 'GRC_user'"
                        >
                          <b-dropdown-item
                            @click.stop="handleResendInviteClick(key)"
                            ><span class="align-middle">
                              <feather-icon icon="SendIcon" class="mr-50" />
                              <span class="align-middle"
                                >Resend Invite Mail</span
                              >
                            </span>
                          </b-dropdown-item>

                          <b-dropdown-item
                            @click.stop="handleCopyLinkClick(key)"
                            ><span class="align-middle">
                              <feather-icon icon="CopyIcon" class="mr-50" />
                              <span class="align-middle"
                                >Copy Invitation Link</span
                              >
                            </span>
                          </b-dropdown-item>
                        </b-dropdown-group>

                        <b-dropdown-divider
                          v-if="user.user_type !== 'GRC_user'"
                        ></b-dropdown-divider>

                        <b-dropdown-item @click.stop="handleEditUserClick(key)"
                          ><span class="align-middle">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span class="align-middle">Edit</span>
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="No Users">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      You can create a new user by clicking on the
                      <b>Create New User</b> button.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
        <div class="card-body">
          <div
            class="demo-spacing-0 d-flex align-items-center justify-content-between"
          >
            <p class="align-middle mb-0">
              Showing {{ from }} to {{ to }} of {{ total }} entries
            </p>

            <b-pagination
              v-model="filters.page"
              :total-rows="total"
              :per-page="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BPagination,
  BAvatar,
  BBadge,
  VBTooltip,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BDropdownDivider,
  BModal,
  BInputGroupAppend,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import UserMixins from "../../mixins/UserMixins";
import UserDetails from "./components/UserDetailsSidebar.vue";
import UserCreateSidebar from "./components/UserCreateSidebar.vue";
import EmptyTableSection from "../../components/EmptyTableSection.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BPagination,
    BAvatar,
    BBadge,
    FeatherIcon,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    UserDetails,
    UserCreateSidebar,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BDropdownDivider,
    BModal,
    BInputGroupAppend,
    EmptyTableSection,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [ResponseMixins, UserMixins],
  data() {
    return {
      userDetailsSidebarEditMode: false,
      showOverlay: false,
      selectedUserId: null,
      inviteUrl: null,
      total: 1,
      from: 1,
      to: 1,
      users: {},
      filters: {
        search: null,
        page: 1,
      },
    };
  },

  watch: {
    filters: {
      handler: function (newValues) {
        this.getUsersAndSetData(newValues);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getUsersAndSetData(this.filters);
    },

    handleUserDetailsViewClick(id) {
      this.userDetailsSidebarEditMode = false;
      this.userClickHandler(id);
    },

    handleEditUserClick(id) {
      this.userDetailsSidebarEditMode = true;
      this.userClickHandler(id);
    },

    handleCopyClick() {
      this.showToast("Invite link copied to clipboard", "success");
      navigator.clipboard.writeText(this.inviteUrl);
      this.hideCopyLinkModal();
      this.inviteUrl = null;
    },

    showCopyLinkModal() {
      this.$refs["copy-link-modal"].show();
    },
    hideCopyLinkModal() {
      this.$refs["copy-link-modal"].hide();
    },

    handleCopyLinkClick(id) {
      this.showCopyLinkModal();
      this.getReinviteUserLinkAndSetData(id);
    },

    getReinviteUserLinkAndSetData(id) {
      this.showOverlay = true;
      this.getReinviteUserLink(id)
        .then((res) => {
          const link = res.data.data.invite_link;
          this.inviteUrl = link;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleResendInviteClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to reinvite this user.", {
          title: "Warning",
          size: "sm",
          okVariant: "success",
          okTitle: "Reinvite",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value) {
            this.reinviteUser(id)
              .then((res) => {
                this.handleResponse(res);
                this.load();
              })
              .catch((err) => {
                this.handleError(err);
              });
          }
        });
    },

    handleCreateUserClick(id) {
      this.selectedUserId = id;
      this.openCreateUserSidebar();
    },

    userClickHandler(id) {
      this.selectedUserId = id;
      this.openUserDetailsSidebar();
    },

    getUsersAndSetData(params) {
      this.showOverlay = true;
      const newParams = {};
      Object.keys(params).map((key) => {
        if (params[key]) {
          newParams[key] = params[key];
        }
      });

      this.getUsers(newParams)
        .then((res) => {
          console.log("User Response:", res);
          const newUsers = {};
          res.data.data.data.forEach((user) => {
            newUsers[user._id] = user;
          });
          this.users = newUsers;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.total = res.data.data.total;
          this.from = res.data.data.from;
          this.to = res.data.data.to;
          console.log("Total", this.total);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    openCreateUserSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-user-create");
    },

    closeCreateUserSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-user-create");
    },

    openUserDetailsSidebar: function () {
      this.toggleBodyScrollbar();
      this.$root.$emit("bv::toggle::collapse", "sidebar-user-details");
    },

    closeUserDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-user-details");
    },

    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName("body")[0];

      if (visible) body.classList.add("overflow-hidden");
      else body.classList.remove("overflow-hidden");
    },
  },
};
</script>


<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
