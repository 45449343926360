<template>
  <sidebar-template title="Create New User" :closeAction="closeAction">
    <template #content>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <validation-observer ref="user_create_form" #default="{ invalid }">
          <b-form @submit.prevent="handleCreateClick">
            <div class="row">
              <div class="col-sm-6">
                <b-form-group label="First Name">
                  <validation-provider
                    vid="firstname"
                    #default="{ errors }"
                    name="First Name"
                    :rules="{
                      required: true,
                      min: 3,
                    }"
                  >
                    <b-form-input v-model="formData.firstname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-sm-6">
                <b-form-group label="Last Name">
                  <validation-provider
                    vid="lastname"
                    #default="{ errors }"
                    name="Last Name"
                    :rules="{
                      required: true,
                      min: 3,
                    }"
                  >
                    <b-form-input v-model="formData.lastname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <b-form-group label="Email">
              <validation-provider
                vid="email"
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input v-model="formData.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile">
              <validation-provider
                vid="mobile"
                #default="{ errors }"
                name="mobile"
                rules="phone"
              >
                <b-form-input type="number" v-model="formData.mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Workspaces">
              <div
                class="w-100 d-flex flex-column align-items-start justify-content-start border-light rounded p-1"
              >
                <template v-for="module in allModules">
                  <workspace-selection-card
                    :key="module.id"
                    :title="module.name"
                    :moduleId="module.id"
                    :userId="userId"
                    @input="handleWorkspaceChange"
                  />
                </template>
              </div>
            </b-form-group>

            <div class="w-100 d-flex justify-content-between align-items-end">
              <b-form-group>
                <validation-provider
                  vid="should_invite"
                  #default="{ errors }"
                  name="should_invite"
                >
                  <b-form-checkbox
                    v-model="formData.should_invite"
                    class="custom-control-primary"
                  >
                    Send invitation mail
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button type="submit" variant="success" :disabled="invalid">
                <b-spinner
                  v-if="isLoading"
                  label="Loading..."
                  small
                  class="mr-50"
                />
                <feather-icon v-else icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Create</span></b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </sidebar-template>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from "../../../mixins/UserMixins";
import UserDetailHeaderItem from "./UserDetailHeaderItem.vue";
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import WorkspaceDetailCard from "./WorkspaceDetailCard.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import WorkspaceSelectionCard from "./WorkspaceSelectionCard.vue";
export default {
  mixins: [UserMixins, ResponseMixins],
  components: {
    UserDetailHeaderItem,
    BOverlay,
    SidebarTemplate,
    BBadge,
    WorkspaceDetailCard,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    WorkspaceSelectionCard,
    BFormCheckbox,
  },

  data() {
    return {
      showOverlay: false,
      isLoading: false,
      user: {},
      currentTenant: null,
      allModules: [],

      formData: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        modules: [],
        should_invite: false,
      },
    };
  },

  mounted() {
    this.load();
  },

  watch: {
    formData: {
      deep: true,
      handler: function (newValue) {
        console.log("Form Data", newValue);
      },
    },
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  methods: {
    load() {
      this.getCurrentTenantDetailsAndSetData();
    },

    handleWorkspaceChange(module) {
      console.log("Workspace Changed", module);
      if (module.value === false) {
        let newModules = this.formData.modules.filter(
          (m) => m.id !== module.id
        );
        this.$set(this.formData, "modules", newModules);
      } else if (module.value === true) {
        let newModules = this.formData.modules;
        newModules.push(module);
        this.$set(this.formData, "modules", newModules);
      }
    },

    getCurrentTenantDetailsAndSetData() {
      this.showOverlay = true;
      this.getCurrentTenantDetails()
        .then((res) => {
          console.log("Current Tenant Details", res);
          this.currentTenant = res.data.data;
          let modules = [];
          res.data.data.modules.map((module) => {
            if (module.status === true) {
              const newModule = {
                name: module.name,
                id: module._id,
                isEnabledForUser: false,
              };
              modules.push(newModule);
            }
          });
          this.allModules = modules;
          console.log(modules);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    createUserAndSetData(data) {
      this.showOverlay = true;
      this.createUser(data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response.status === 422) {
            this.$refs.user_create_form.setErrors(err.response.data.errors);
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleCreateClick() {
      this.createUserAndSetData(this.formData);
    },
  },
};
</script>

<style>
</style>