<template>
<div class="d-flex flex-column align-items-start justify-content-center h-auto w-100 border-light shadow-sm mb-75 p-1">
<h5 class="mb-1">{{title}}</h5>

<p class="mb-25">Modules:</p>
<div class="w-100 h-auto d-flex align-items-center justify-content-start flex-wrap">
<template v-for="item in modules">
    <b-badge variant="light-primary" :key="item._id" class="mr-50">{{item.name}}</b-badge>
</template>
</div>

</div>
  
</template>

<script>
import {BBadge} from 'bootstrap-vue'

export default {

    components:{
        BBadge,
    },

    props:{
        title:{
            type: String,
            required: true,
        },

        modules:{
            type: Array,
            required: true,
        }
    }

}
</script>

<style>

</style>