var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-template',{attrs:{"title":"Create New User","closeAction":_vm.closeAction},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('validation-observer',{ref:"user_create_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleCreateClick.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('validation-provider',{attrs:{"vid":"firstname","name":"First Name","rules":{
                    required: true,
                    min: 3,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.firstname),callback:function ($$v) {_vm.$set(_vm.formData, "firstname", $$v)},expression:"formData.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('validation-provider',{attrs:{"vid":"lastname","name":"Last Name","rules":{
                    required: true,
                    min: 3,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.lastname),callback:function ($$v) {_vm.$set(_vm.formData, "lastname", $$v)},expression:"formData.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Mobile"}},[_c('validation-provider',{attrs:{"vid":"mobile","name":"mobile","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Workspaces"}},[_c('div',{staticClass:"w-100 d-flex flex-column align-items-start justify-content-start border-light rounded p-1"},[_vm._l((_vm.allModules),function(module){return [_c('workspace-selection-card',{key:module.id,attrs:{"title":module.name,"moduleId":module.id,"userId":_vm.userId},on:{"input":_vm.handleWorkspaceChange}})]})],2)]),_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-end"},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":"should_invite","name":"should_invite"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.formData.should_invite),callback:function ($$v) {_vm.$set(_vm.formData, "should_invite", $$v)},expression:"formData.should_invite"}},[_vm._v(" Send invitation mail ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"label":"Loading...","small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Create")])],1)],1)],1)]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }